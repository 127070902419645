<template>
  <v-navigation-drawer
      v-model="drawer"
      width="400"
      app
      temporary
      right
      class="wost-drawer"
  >
    <div class="navigation-head">
      <span>FILTRAR DATOS DE HISTORIAL DE CAJA</span>
    </div>
    <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
      <template>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="filters.office_id"
                :items="offices"
                label="Sucursal"
                hide-details
                outlined
                :item-text="customText"
                item-value="id"
                clearable
                @change="handleSelectOffice"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="filters.till_id"
                :items="tills"
                label="Caja"
                hide-details
                outlined
                :item-text="customText"
                item-value="id"
                clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="filters.date_from"
                    label="Desde"
                    readonly
                    clearable
                    v-on="on"
                    outlined
                    hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="filters.date_from" @input="menuFrom = false" no-title>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-menu
                v-model="menuUntil"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="filters.date_until"
                    label="Hasta"
                    readonly
                    clearable
                    v-on="on"
                    outlined
                    hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="filters.date_until" @input="menuUntil = false" no-title>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </div>
    <div class="mt-3 pl-3 pt-3 pr-3">
      <template>
        <v-row>
          <v-col cols="6">
            <v-btn block height="40" dark color="red" @click="handleClearFilters">
              <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
              Quitar filtros
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn dark color="blue" block height="40" @click="handleSearch">
              <v-icon size="16" class="mr-1">fas fa-search</v-icon>
              Filtrar datos
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {filter} from "core-js/internals/array-iteration";

export default {
  name: 'TillsFilterPaginated',
  data() {
    return {
      drawer: false,
      menuFrom: false,
      menuUntil: false,
      filters: {
        company_id: null,
        sede_id: null,
        office_id: null,
        till_id: null,
        date_from: null,
        date_until: null
      }
    }
  },
  computed: {
    ...mapState('authentication', [
      'company_id'
    ]),
    ...mapState('sedes', [
      'sedes'
    ]),
    ...mapState('offices', [
      'offices'
    ]),
    ...mapState('tills', [
      'tills'
    ]),
  },
  methods: {
    ...mapActions('offices', [
      'getOffices',
      'run_set_offices'
    ]),
    ...mapActions('tills', [
      'getTills',
      'empty_tills'
    ]),
    ...mapActions('report-tills', [
      'getTillsOperations',
      'setFilters',
      'changeFilterPaginated'
    ]),
    show() {
      this.filters.company_id = this.company_id;
      this.drawer = !this.drawer;
    },
    customText(item) {
      let text = item.name;
      return text;
    },
    async handleSelectOffice(value) {
      if (value !== undefined && value !== null) {
        let filters = {
          sede_id: this.filters.sede_id,
          office_id: value
        }
        await this.getTills(filters);
      } else {
        this.empty_tills();
      }
    },
    closeForm() {
      this.drawer = false;
    },
    clearData() {
      this.filters.sede_id = null;
      this.filters.office_id = null;
      this.filters.till_id = null;
      this.filters.date_from = null;
      this.filters.date_until = null;
      this.menuFrom = false;
      this.menuUntil = false;
      this.setFilters(null);
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
    },
    handleClearFilters() {
      this.clearData();
      this.getTillsOperations({...this.filters, paginated: true, itemsPerPage: 10});
    },
    async handleSearch() {
      let filters_data = Object.assign({}, this.filters);
      console.log(filters_data)
      this.changeFilterPaginated({...filters_data, paginated: true, itemsPerPage: 10})
      this.setFilters(filters_data);
      await this.getTillsOperations({...filters_data, paginated: true, itemsPerPage: 10});
      this.closeForm();
    }
  }
}
</script>

<style scoped>

</style>