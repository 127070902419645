<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Historial de cajas</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn dark color="#023145" width="140" class="float-right" @click="handleShowFilters">
            Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-btn
              dark
              color="success"
              width="140"
              class="float-right"
              @click="handleDownloadReport">
            Descargar XLS
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-1">
          <v-card>
            <v-data-table
                :headers="headers"
                :items="operations"
                item-key="id"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                fixed-header
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait">
              >
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todos los movimientos
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.created_at="{item}">
                <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
              </template>

              <template v-slot:item.hour_open="{item}">
                <span>{{ item.created_at | moment('HH:mm') }}</span>
              </template>

              <template v-slot:item.amount_open="{item}">
                <span>{{ item.amount_open | currency('S/') }}</span>
              </template>

              <template v-slot:item.total="{item}">
                                <span>{{
                                    (
                                        parseFloat(item.amount_open) +
                                        parseFloat(item.amount_income) +
                                        parseFloat(item.amount_cash) +
                                        parseFloat(item.amount_card) -
                                        parseFloat(item.amount_expense) -
                                        parseFloat(item.amount_canceled)
                                    ) | currency('S/')
                                  }}</span>
              </template>

              <template v-slot:item.amount_close_cash="{item}">
                <span>{{ item.amount_close_cash | currency('S/') }}</span>
              </template>

              <template v-slot:item.amount_close_card="{item}">
                <span>{{ item.amount_close_card | currency('S/') }}</span>
              </template>

              <template v-slot:item.amount_close_transfer="{item}">
                <span>{{ item.amount_close_transfer | currency('S/') }}</span>
              </template>

              <template v-slot:item.amount_close_yape="{item}">
                <span>{{ item.amount_close_yape | currency('S/') }}</span>
              </template>

              <template v-slot:item.status="{item}">
                                <span v-if="item.status == 'en uso'" class="uso">
                                    {{ item.status }}
                                </span>
                <span v-if="item.status == 'cerrada'" class="cerrada">
                                    {{ item.status }}
                                </span>
              </template>

              <template v-slot:item.condition="{item}">
                                <span v-if="item.condition == 'cuadre'" class="cuadre">
                                    {{ item.condition }}
                                </span>
                <span v-else-if="item.condition == 'faltante'" class="faltante">
                                    {{ item.condition }}
                                </span>
                <span v-else-if="item.condition == 'sobrante'" class="sobrante">
                                    {{ item.condition }}
                                </span>
              </template>

              <template v-slot:item.updated_at="{item}">
                                <span v-if="item.status == 'cerrada'">
                                    {{ item.updated_at | moment('DD/MM/YYYY') }}
                                </span>
              </template>

              <template v-slot:item.hour_close="{item}">
                                <span v-if="item.status == 'cerrada'">
                                    {{ item.updated_at | moment('HH:mm') }}
                                </span>
              </template>

              <template v-slot:item.detail="{item}">
                <v-btn icon small dark color="blue" @click="handleViewDetail(item)">
                  <v-icon size="14">fas fa-bars</v-icon>
                </v-btn>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

    </template>

    <tills-filters ref="tillsFilters"></tills-filters>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import router from '@/router/index';
import TillsFilters from '../../../components/reports/TillsFiltersPaginated';
import OfficeLogin from '../../../components/auth/OfficeLogin';

export default {
  name: 'ReportTills',
  components: {
    TillsFilters,
    OfficeLogin
  },
  mounted() {
    this.changeFilterPaginated( {
      company_id: null,
          sede_id: null,
          office_id: null,
          till_id: null,
          date_from: null,
          date_until: null
    })
  },
  watch: {
    options: {
      handler() {
        this.loading = true
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_product
        console.log(this.filtersPaginated)
        if (this.filtersPaginated.company_id!=null || this.filtersPaginated.sede_id!=null ||
            this.filtersPaginated.office_id!=null || this.filtersPaginated.till_id!=null ||
            this.filtersPaginated.date_from!=null || this.filtersPaginated.date_until!=null
        ){
          this.getTillsOperations({
            paginated: true,
            page: this.pageSelected,
            itemsPerPage: this.perPage,
            company_id: this.filtersPaginated.company_id,
            sede_id: this.filtersPaginated.sede_id,
            office_id: this.filtersPaginated.office_id,
            till_id: this.filtersPaginated.till_id,
            date_until: this.filtersPaginated.date_until,
            date_from: this.filtersPaginated.date_from,
          }).then(res => this.loading = false).catch(err => this.loading = false)
        }else{
          if (search != null && search.trim().length > 0) {
            this.getTillsOperations({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
              name: search, company_id: this.company_id,

            }).then(res => this.loading = false).catch(err => this.loading = false)
          } else {
            this.getTillsOperations({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage, company_id: this.company_id,
            }).then(res => this.loading = false).catch(err => this.loading = false)
          }
        }

      },
      deep: true,
    }
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: 'Reportes',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'SUCURSAL', value: 'office_name', sortable: false},
        {text: 'CAJA', value: 'till_name', sortable: false},
        {text: 'FECHA', value: 'created_at', sortable: false},
        {text: 'HORA', value: 'hour_open', sortable: false},
        {text: 'M. APERTURA', value: 'amount_open', sortable: false},
        {text: 'TOTAL', value: 'total', sortable: false},
        {text: 'CIERRE EFECTIVO', value: 'amount_close_cash', sortable: false},
        {text: 'CIERRE TARJETA', value: 'amount_close_card', sortable: false},
        {text: 'CIERRE TRANSFERENCIA', value: 'amount_close_transfer', sortable: false},
        {text: 'CIERRE YAPE', value: 'amount_close_yape', sortable: false},
        {text: 'ESTADO', value: 'status', sortable: false, align: 'center'},
        {text: 'CONDICIÓN', value: 'condition', sortable: false, align: 'center'},
        {text: 'F. CIERRE', value: 'updated_at', sortable: false},
        {text: 'H. CIERRE', value: 'hour_close', sortable: false, align: 'end'},
        {text: '', value: 'detail', sortable: false, align: 'end'},
      ],
      loading: false,
      dataTable: {page: 1},
      options: {},
      pageSelected: 1,
      perPage: 10,
      search_product: null
    }
  },
  computed: {
    ...mapState('authentication', [
      'company_id'
    ]),
    ...mapState('report-tills', [
      'operations',
      'filters',
      'file_excel',
      'paginated',
      'filtersPaginated'
    ])
  },
  methods: {
    ...mapActions('offices', [
      'getOffices'
    ]),
    ...mapActions('report-tills', [
      'getTillsOperations',
      'setFilters',
      'emptyReport',
      'selectTill',
      'tillsExcel',
      'changeFilterPaginated'
    ]),
    handleShowFilters() {
      this.$refs.tillsFilters.show();
    },
    handleViewDetail(till) {
      this.selectTill(till);
      router.push('/app/reportes/detalle-caja');
    },
    async handleDownloadReport() {
      let filters_data = Object.assign({}, this.filters);
      filters_data.company_id = this.company_id;
      await this.tillsExcel(filters_data);
      if (this.file_excel) {
        let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_excel;
        window.open(url);
      }
    }
  },
  created() {
    this.emptyReport();
    this.setFilters(null);
    // this.loading=true
    // this.getTillsOperations({
    //   company_id: this.company_id,paginated:true
    // }).then(res=>this.loading=false).catch(err=>this.loading=false);
    this.getOffices({
      company_id: this.company_id
    });
  }
}
</script>

<style scoped>
.uso {
  display: inline-block;
  border: 1px solid #4CAF50;
  border-radius: 4px;
  width: 70px;
  text-align: center;
  color: #4CAF50;
  margin-left: 20px;
}

.cerrada {
  display: inline-block;
  border: 1px solid #F44336;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  color: #F44336;
  margin-left: 20px;
}

.cuadre {
  display: inline-block;
  border: 1px solid #4CAF50;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 20px;
  background-color: #4CAF50;
}

.faltante {
  display: inline-block;
  border: 1px solid #F44336;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 20px;
  background-color: #F44336;
}

.sobrante {
  display: inline-block;
  border: 1px solid #2196F3;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 20px;
  background-color: #2196F3;
}
</style>